<template>
  <div class="coupon-reward-item">
    <div class="is-flex align-items-center justify-flex-start">
      <div
        class="coupon-img-wrapper"
      >
        <img
          :src="type === 'discount' ? require('@/assets/img/voucher.png') : require('@/assets/img/coupon.png')"
        />
      </div>
      <div class="has-margin-left-15 flex-1">
        <div class="has-text-black _fs-16 _fw-500">{{name}}</div>
        <span class="has-margin-top-5 has-text-white _fs-10 _fw-500 has-background-primary has-padding-left-5 has-padding-right-5">{{$t('common.level', {level})}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CouponRewardItem',
  props: {
    name: String,
    imgPath: String,
    level: String,
    type: String
  },
  methods: {
    replaceByDefault (e) {
      e.target.src = require('@/assets/img/coupon-default.png')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/__variables.scss';
.coupon-reward-item {
  padding: 20px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  .coupon-img-wrapper {
    height: 50px;
    width: 50px;
    /* border-radius: 100%;
    background: $primary; */
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
