<template>
  <div class="my-coupon-page">
    <Backbar
      :title="$t('rewardDetailPage.title')"
      @onBackClick="$router.go(-1)"
    />
    <div class="reward-section has-padding-20 has-background-white">
      <div class="is-flex justify-space-between">
        <div class="_fw-500 _fs-20 has-text-black">{{$t('common.level', {level: $i18n.locale === 'th' ? currentMemberCard.name || '': currentMemberCard.name_en || ''})}}</div>
        <div></div>
      </div>
      <div>
        <div class="has-margin-top-5 _fw-500 _fs-14 has-text-black40">{{$t('common.purchaseBalance')}}</div>
        <div class="has-margin-top-10 _fw-900">
          <span class="has-text-primary _fs-32">{{pointReceive | formatNumber}}</span>
          <span class="_fs-20 has-text-black40" v-if="nextMemberCard.name">/{{nextPointLevel | formatNumber}}</span>
          <span class="_fs-20 has-text-black40"> {{$t('common.baht')}}</span>
        </div>
        <div class="has-margin-top-10" v-if="nextMemberCard.name">
          <ProgressBar :progress="progressMemberLevel"/>
        </div>
        <div class="is-flex flex-column align-items-flex-end" v-if="nextMemberCard.name">
          <div class="has-margin-top-20 _fw-bold _fs-16">
            <span class="has-text-black40">{{$t('rewardDetailPage.levelup1')}} </span>
            <span class="has-text-primary">{{$t('rewardDetailPage.levelup2', {balance: balanceString})}} </span>
            <span class="has-text-black40">{{$t('rewardDetailPage.levelup3')}}</span>
            <span class="has-text-black40">{{$t('rewardDetailPage.levelup4', {expiredAt, level: $i18n.locale === 'th' ? nextMemberCard.name || '' : nextMemberCard.name_en || '' })}}</span>
          </div>
          <div class="_fs-12 has-margin-top-5" v-if="nextMemberCard.name">
            <div class="has-text-black40">{{$t('rewardDetailPage.note')}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="has-margin-top-10">
      <div class="is-flex justify-space-between align-items-center has-background-white has-padding-left-20 has-padding-right-20 has-padding-top-15">
        <div class="_fw-500 _fs-16 has-text-black is-flex align-items-center">
          <div class="has-margin-right-5">{{$t('rewardDetailPage.exclusiveBenefits')}}</div>
          <img src="@/assets/icon/alert-circle.svg"/>
        </div>
      </div>
      <Switchbar
        :data="memberCardsName"
        @onChangePage="onSwitchbarChage"
      />
    <div class="coupon-section has-padding-20 has-background-white">
      <div v-if="couponsBenefit && couponsBenefit.length > 0">
        <CouponRewardItem
          v-for="(coupon, index) in couponsBenefit"
          :key="index"
          :name="$i18n.locale === 'th' ? coupon.name : coupon.name_en"
          :img-path="coupon.thumbnail_url || ''"
          :level="$i18n.locale === 'th' ? selectedMemberCard.name : selectedMemberCard.name_en"
          :type="coupon.type"
        />
      </div>
      <div class="has-text-centered _fs-12 has-margin-top-10" v-else>{{$t('common.noData')}}</div>
    </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'
import { mapActions, mapState, mapGetters } from 'vuex'
import { mapWaitingActions } from 'vue-wait'
import Backbar from '@/components/Backbar.vue'
import ProgressBar from '@/components/ProgressBar.vue'

import CouponRewardItem from '@/components/coupon/CouponRewardItem.vue'
import Switchbar from '@/components/Switchbar.vue'

export default {
  name: 'RewardDetail',
  components: {
    Backbar,
    ProgressBar,
    CouponRewardItem,
    Switchbar
  },
  data () {
    return {
      couponIndex: 0
    }
  },
  computed: {
    ...mapState('Auth', ['user']),
    ...mapGetters('Auth', [
      'pointReceive',
      'userPackage',
      'eventPackage',
      'currentMemberCard',
      'nextMemberCard',
      'nextPointLevel',
      'remainPointNextLevel',
      'memberCards',
      'progressMemberLevel'
    ]),
    expiredAt () {
      if (this.userPackage && this.userPackage.expired_at) {
        return moment(this.userPackage.expired_at).locale(this.$i18n.locale).format('DD MMM YYYY')
      } else {
        return ''
      }
    },
    memberCardsName () {
      return this.memberCards.map((obj) => {
        return this.$i18n.locale === 'th' ? obj.name : obj.name_en
      })
    },
    selectedMemberCard () {
      return (this.memberCards && this.memberCards[this.couponIndex]) || {}
    },
    couponsBenefit () {
      return (this.selectedMemberCard && this.selectedMemberCard.coupons) || []
    },
    balanceString () {
      return Vue.filter('formatNumber')(this.remainPointNextLevel)
    }

  },
  methods: {
    ...mapActions({
      fetchCustomer: 'Auth/fetchCustomer'
    }),
    ...mapWaitingActions('Auth', {
      fetchCustomer: 'app loading'
    }),
    async onSwitchbarChage (index) {
      this.couponIndex = index
      await this.fetchCustomer()
    }
  },
  async mounted () {
    await this.fetchCustomer()
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/__variables.scss';
.switch-wrapper {
  position: relative;
  overflow-x: auto;
  min-height: 60px;
  .switch {
    min-height: 60px;
    color: white;
    position: relative;
    color: $black;
    min-width: 80px;
    padding-bottom: 4px;
  }
  .indicator {
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    z-index: 2;
    height: 2px;
    background: $light-green;
    border-radius: 2px;
  }
}
.indicator-bar {
  width: 100%;
  z-index: 1;
  height: 2px;
  border-radius: 2px;
  background: $light-gray;
  margin-top: -2px;
}
</style>
