<template>
  <div class="progress-bar">
    <div class="progress" :style="{ width: `${progress}%`}"></div>
  </div>
</template>

<script>
export default {
  props: {
    progress: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/__variables.scss';
.progress-bar {
  width: 100%;
  height: 12px;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.1);
  .progress {
    height: 12px;
    border-radius: 6px;
    background-color: $primary;
  }
}
</style>
